<template>
  <a-layout class="main-container">
    <div class="apply-container">
      <div class="apply-form">
        <img src="@/assets/member/img-arts.png" alt="" class="img-arts" />
        <div class="apply-title">个人入会申请表</div>
        <div class="apply-tip">
          <p class="tip-red">请仔细填写，谢谢合作</p>
          <p class="tip-gray">*填写前请准备好电子证件照文件</p>
          <p class="tip-gray">
            如有《推荐单位推荐人意见单》或《学会审批意见单》可提前备好电子文件以便上传（非必填）
          </p>
        </div>
        <div class="apply-divider"></div>
        <a-form-model :model="form" ref="form">
          <div class="form-title">
            1.姓名
          </div>
          <a-form-model-item prop="realName">
            <a-input class="bottom" />
          </a-form-model-item>
          <div class="form-title">
            2.请上传一寸电子证件照
          </div>
          <a-form-model-item prop="img">
            <a-upload-dragger name="file" action="" class="upload">
              <a-icon type="plus" class="upload-icon" />
              <p class="ant-upload-text">
                选择文件
              </p>
              <p class="ant-upload-hint">
                最多一个，单个文件大小不超过30MB
              </p>
            </a-upload-dragger>
          </a-form-model-item>
          <div class="form-title">
            3.个人信息
          </div>
          <a-form-model-item label="性别">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="出生年月">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="身份证号">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="民族">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="政治面貌">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="文化程度">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="专业">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="职称">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="何时参加工作">
            <a-input />
          </a-form-model-item>
          <a-form-model-item label="现所在单位及职务">
            <a-input />
          </a-form-model-item>
        </a-form-model>
        <a-button class="btn-submit">提交申请</a-button>

        <div class="apply-scan">
          <div>扫码答题</div>
          <img src="@/assets/member/question-qrcode.png" alt="" />
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      form: {}
    };
  }
};
</script>

<style scoped lang="less">
.main-container {
  min-height: 100vh;
  background-color: #f1f2f5;
  box-sizing: border-box;
  padding-top: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .apply-container {
    width: 830px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 50px 0;
    .apply-form {
      width: 820px;
      box-sizing: border-box;
      padding: 66px 42px;
      background-color: white;
      position: relative;
      .img-arts {
        width: 100%;
        object-fit: contain;
      }
      .apply-title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 6px;
        margin-top: 10px;
        text-align: center;
      }
      .apply-tip {
        margin-top: 36px;
        font-size: 16px;
        p {
          margin-bottom: 0;
        }
        .tip-red {
          color: #b85648;
          font-weight: 500;
        }
        .tip-gray {
          color: #90999b;
          font-weight: 400;
        }
      }
      .apply-divider {
        height: 3px;
        background-color: #68a2ee;
        margin: 20px 0 40px 0;
      }
      .form-title {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 15px;
      }
      .form-title:after {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
      .ant-input {
        width: 360px;
      }
      .bottom {
        margin-bottom: 40px;
      }
      /deep/ .ant-upload {
        width: 420px;
        margin-bottom: 40px;
        padding-bottom: 0;
      }
      /deep/ .ant-form-item {
        margin-bottom: 0;
      }
      /deep/ .ant-form-item-label {
        line-height: 24px;
      }
      .upload-icon {
        margin: 25px 0 20px 0;
        font-size: 40px;
      }
      .ant-upload-text {
        color: rgba(0, 0, 0, 0.45);
      }
      .btn-submit {
        margin-top: 40px;
      }
      .apply-scan {
        position: absolute;
        right: -110px;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        padding: 15px;
        img {
          width: 70px;
          object-fit: contain;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
